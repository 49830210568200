import React from "react"
import Helmet from "react-helmet"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = () => {
  return (
  <Layout>
    <Helmet>
      <script src="https://donorbox.org/widget.js" paypalExpress="false"></script>
    </Helmet>
    <SEO title="Home" />
    <div className="section container anchor">
      <div className="columns">
        <div className="column is-10 is-offset-1 inset">
          <h1 className="is-size-1" style={{ marginTop: 0}}>Make a Donation</h1>
          <iframe allowpaymentrequest="" frameborder="0" height="900px" name="donorbox" scrolling="no" seamless="seamless" src="https://donorbox.org/embed/sugar-land-legacy-foundation-donation?designation=Area+of+greatest+need&default_interval=o&show_content=true" style={{maxWidth: '100%', minWidth: '100%', maxHeight: 'none !important' }} width="100%"></iframe>
        </div>
      </div>
    </div>
  </Layout>
)}

export default IndexPage
